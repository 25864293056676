import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#060B1F',
      contrast: '#4BFBE7',
      secondary: '#4BFBE7',
      light: '#FFFFFF',
      gray: '#D8D8D8',
      light2: '#F8F8F7',
    },

    text: {
      main: '#2D3451',
      contrast: '#4BFBE7',
      secondary: '#2D3451',
      gray: '#C5C5C5',
      light: '#FFFFFF',
      dark: '#000',
    },
  },

  font: {
    family: {
      main: 'Axis',
      secondary: 'Open Sans',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      18: '18px',
      xm: '20px',
      l: '24px',
      28: '28px',
      xl: '32px',
      36: '36px',
      40: '40px',
      xxl: '45px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) =>
    Object.keys(props.theme.color.face).map((key) => `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`)}

  ${(props) =>
    Object.keys(props.theme.color.text).map((key) => `.color-${key} { color: ${props.theme.color.text[key]}; }`)}

  ${(props) =>
    Object.keys(props.theme.font.family).map((key) => `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`)}

  ${(props) =>
    Object.keys(props.theme.font.size).map((key) => `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`)}

  ${(props) =>
    Object.keys(props.theme.font.weight).map((key) => `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`)}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme
